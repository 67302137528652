import React from 'react';
import PropTypes from 'prop-types';
import ArticleCard from '@components/Cards/Article';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import useCaseStudiesOverview from '@hooks/useCaseStudies';
import styles from './projects.module.scss';

const Projects = ({ data }) => {
    const caseStudies = useCaseStudiesOverview();
    return (
        <Section className={styles.projectsSection}>
            <Grid>
                <Row>
                    <Column md="9">
                        <SectionHeader
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
                <div className={styles.projects}>
                    {caseStudies &&
                        caseStudies
                            .filter(
                                caseStudy => !caseStudy.slug.includes('ott')
                            )
                            .map(caseStudy => {
                                const article = {
                                    slug: caseStudy.slug || '',
                                    image: caseStudy.coverImage,
                                    topic: caseStudy.heading,
                                    title: caseStudy.subHeading,
                                    published: caseStudy.published,
                                };
                                return (
                                    <div
                                        key={`box-project-${caseStudy.heading}`}
                                        className={styles.itemProjects}
                                    >
                                        <ArticleCard
                                            key={caseStudy.published}
                                            article={article}
                                            sizeTitle="h5"
                                            classImageWrapper={
                                                styles.imageWrapper
                                            }
                                            clientLogo={caseStudy.clientLogo}
                                        />
                                    </div>
                                );
                            })}
                </div>
            </Grid>
        </Section>
    );
};

Projects.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
    }).isRequired,
};

export default Projects;
