import { useStaticQuery, graphql } from 'gatsby';

export const formatData = nodesData => {
    if (!nodesData || !Array.isArray(nodesData)) {
        return nodesData;
    }

    return nodesData.reduce((acc, el) => {
        acc.push({
            image: el.frontmatter.image,
            title: el.frontmatter.title,
            published: el.frontmatter.published,
        });
        return acc;
    }, []);
};

export default () => {
    const data = useStaticQuery(graphql`
        query GetIndustries {
            allIndustries: allMarkdownRemark(
                sort: { fields: [frontmatter___order], order: ASC }
                filter: { fields: { slug: { regex: "/our-industries/" } } }
            ) {
                industries: nodes {
                    frontmatter {
                        title
                        published
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                    presentationWidth
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const { industries } = data.allIndustries;
    return industries && formatData(industries);
};
