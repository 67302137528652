import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '@components/SectionHeader';
import Section from '@components/core/Section';
import Grid, { Column, Row } from '@components/core/Grid';
import styles from './how-we-work.module.scss';

const HowWeWork = ({ data }) => {
    return (
        <Section className={styles.howWeWorkSection}>
            <Grid>
                <Row>
                    <Column md="9">
                        <SectionHeader
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

HowWeWork.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
    }).isRequired,
};

export default HowWeWork;
