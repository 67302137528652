import React from 'react';
import Layout from '@components/Layout';
import Section from '@components/core/Section';
import PageHeader from '@components/PageHeader';
import SEO from '@components/SEO';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Projects from '@sections/Work/Projects/Projects';
import Clients from '@sections/Work/Clients/Clients';
import OurIndustries from '@sections/Work/OurIndustries/OurIndustries';
import HowWeWork from '@sections/Work/HowWeWork/HowWeWork';

// TODO: Fix it with isSectionReel and videoUrl (reel) from CMS
const WorkPage = ({
    data: {
        markdownRemark: {
            frontmatter: {
                title,
                seo,
                pageHeader,
                clients,
                howWeWork,
                ourIndustries,
                projects,
            },
        },
    },
}) => {
    const infoHeader = {
        ...pageHeader,
        isSectionReel: false,
    };
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout disableFooterParallax={false}>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Section>
                <PageHeader infoHeader={infoHeader} />
            </Section>
            <HowWeWork data={howWeWork} />
            <OurIndustries data={ourIndustries} />
            <Projects data={projects} />
            <Clients data={clients} />
        </Layout>
    );
};

WorkPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query WorkPage {
        markdownRemark(fields: { slug: { eq: "/pages/work/" } }) {
            frontmatter {
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                pageHeader {
                    title
                    headerDescription: description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    previewUrl {
                        src: publicURL
                    }
                    videoUrl
                    videoCms {
                        src: publicURL
                    }
                }
                howWeWork {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                ourIndustries {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                projects {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                clients {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
            }
        }
    }
`;

export default WorkPage;
