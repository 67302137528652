import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useOurIndustries from '@hooks/Work/useOurIndustries';
import Eyebrow from '@components/core/Eyebrow/Eyebrow';
import SectionHeader from '@components/SectionHeader';
import Grid, { Column, Row } from '@components/core/Grid';
import ParallaxSection from '@components/core/ParallaxSection';
import styles from './our-industries.module.scss';

const OurIndustries = ({ data }) => {
    const ourIndustries = useOurIndustries();
    const breakpoints = useBreakpoint();

    return (
        <ParallaxSection
            dataTheme="dark"
            variant="dark"
            className={styles.ourIndustriesSection}
            disabled={!breakpoints.md}
        >
            <Grid>
                <Row>
                    <Column md="9">
                        <SectionHeader
                            variant="dark"
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
                <div className={styles.industriesContainer}>
                    {ourIndustries.map(industry => {
                        const { childImageSharp = {} } = industry.image || {};
                        return (
                            <div
                                key={`work-${industry.published}`}
                                className={styles.industriesItem}
                            >
                                {childImageSharp && childImageSharp.fluid && (
                                    <Img fluid={childImageSharp.fluid} />
                                )}
                                <Eyebrow
                                    size="paragraphMd"
                                    className={styles.industryTitle}
                                >
                                    {industry.title}
                                </Eyebrow>
                            </div>
                        );
                    })}
                </div>
            </Grid>
        </ParallaxSection>
    );
};

OurIndustries.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
    }).isRequired,
};

export default OurIndustries;
