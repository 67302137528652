import { useStaticQuery, graphql } from 'gatsby';

export const formatData = nodesData => {
    if (!nodesData || !Array.isArray(nodesData)) {
        return nodesData;
    }

    return nodesData.map(caseStudy => {
        return {
            slug: caseStudy.fields.slug,
            subHeading: caseStudy.data.header.sectionHeader.title,
            ...caseStudy.data.featuredInfo.fieldsInfo,
        };
    });
};

export default () => {
    const data = useStaticQuery(graphql`
        query GetAllActiveCaseStudies {
            result: allMarkdownRemark(
                limit: 4
                filter: { fields: { slug: { glob: "/work/*" } } }
                sort: { order: DESC, fields: [frontmatter___published] }
            ) {
                nodes {
                    data: frontmatter {
                        header {
                            sectionHeader {
                                title
                                eyebrow
                                description
                            }
                        }
                        featuredInfo {
                            fieldsInfo {
                                heading
                                clientName
                                clientLogo {
                                    publicURL
                                    childImageSharp {
                                        fluid(maxWidth: 125) {
                                            ...GatsbyImageSharpFluid
                                            presentationWidth
                                        }
                                    }
                                }
                                published(formatString: "YYYY-MM-DD HH:mm:ss")
                                coverImage {
                                    publicURL
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                            presentationWidth
                                        }
                                    }
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
                totalCount
            }
        }
    `);

    const { nodes } = data.result;

    return nodes && formatData(nodes);
};
